<template>
    <div class="article-blog-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="article-blog__banner article-blog__banner--eclipse">
            <img
                class="article-blog__banner-img"
                src="@/assets/blog/driving-sales-home-for-the-holidays.jpg"
                alt="Driving sales home for the holidays"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="article-blog__banner-title">
                DRIVING SALES HOME FOR THE HOLIDAYS: SIMPLE WEB DEVELOPMENT SOLUTIONS FOR BOOSTING E-COMMERCE DURING THE HOLIDAYS
            </h1>
        </section>
        <div class="container article-blog section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        The last months of the year kick off a wild ride for retailers, running full-throttle from early October to the start of the new year. It's the grand finale, the most awesome time of the year, and for e-commerce hotshots, it's also the craziest.
                    </p>
                    <p>
                        Post-pandemic, people are clicking their way through the digital aisles more than ever, leaving traditional in-store shopping far behind. So, if you're running an online shop, it's time to spruce up your game.
                    </p>
                    <p>
                        This holiday bash in the US is set to be nothing short of spectacular, with sales projected to hit a shocking high between $278 billion and $284 billion. Now, if you're an online store owner, you'd be wise to jingle all the way to success by diving into the world of custom web development solutions.
                    </p>
                    <p>
                        Why, you ask? Well, in this article, we're about to unwrap the secrets behind why investing in tailor-made web solutions is the North Star guiding e-commerce businesses through the twinkling lights of the holiday season. The holidays are like the grand finale of a fireworks show, and it's your chance to light up the sky and end the business year with a bang, setting the stage for a fabulous new year ahead. For online store owners, this festive stretch isn't just about decking the halls; it's the prime time to boost revenue and hit those profit goals out of the park.
                    </p>
                    <p>
                        But, and there's always a but, you've got to know the secrets of online holiday shopping like the back of your mittens. So, how do you do it? At first sight, it sounds simple as it's all about directing the traffic straight to your online store and turning those site visitors into cheerful buyers. Miss this crucial step, and you might as well be a snowflake in a blizzard – lost among a flurry of online retailers, all competing for the same slice of the holiday pie.
                    </p>
                    <blockquote>
                        <i>
                            For a leading UK-based Home & Accessories retailer, we developed a number of custom features that pinpointed financial challenges and significantly enhanced website usability. The best example was Black Friday a few years ago, when the website welcomed maximum visitors at once, but despite that, the server was not overloaded, and due to a clear presentation, consumers could quickly choose the best option with a special discount. Our client doubled his profit & attracted new loyal buyers.
                        </i>
                    </blockquote>
                    <p>
                        Now, let's talk turkey. The success of your startup or small business website during this holiday extravaganza isn't a one-size-fits-all deal. It's like a beautifully wrapped gift with several layers of excitement. Here are the key aspects that can make or break your e-commerce sleigh ride:
                    </p>
                    <ul>
                        <li>
                            Brand Positioning
                        </li>
                        <li>
                            Responsive Web Design
                        </li>
                        <li>
                            Healthy User Engagement
                        </li>
                        <li>
                            Customer Data Safety
                        </li>
                        <li>
                            Performance Optimization
                        </li>
                        <li>
                            Customized User Experience
                        </li>
                    </ul>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/driving-sales-home-for-the-holidays/driving-sales-home-for-the-holidays-img-1.jpg"
                            alt="Article image 1"
                            title="Article image 1"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        SIMPLE WEB SOLUTIONS FOR HOLIDAY SEASON
                    </h2>
                    <p>
                        Black Friday and Cyber Monday aren't just days anymore; they're entire seasons of deals and steals. The digital rush during these major shopping events can make or break a business, pushing online platforms to the brink and causing costly website crashes. But fear not, savvy e-commerce entrepreneurs, because we've got the lowdown on how to navigate this high-stakes game and emerge victorious.
                    </p>
                    <p>
                        Gone are the days when the shopping craze was confined to the post-Thanksgiving weekend. Retail giants are kicking off their Black Friday extravaganzas as early as the first half of October, turning the entire holiday shopping season into a digital spectacle. This shift requires online merchants to up their game and get strategic about their digital infrastructure.
                    </p>
                    <blockquote>
                        <i>
                            <b>Not all tasks require complex solutions</b> - our client needed a fast home page update for their online store before Christmas. Facing tight deadlines, we worked smart, not complex. We switched the Magento site theme to a festive one, spiced it up with interactive elements, animations, and hot-off-the-press offers. Our knack for quick and effective updates ensures your Magento store shines bright for the holiday season.
                        </i>
                    </blockquote>
                    <p>
                        To ride the wave of success this holiday season and beyond, here are some quick and user-friendly tips to whip your website into peak performance:
                    </p>
                    <p>
                        <b>Festive Landing Pages</b><br>
                        Create dedicated landing pages with holiday-themed designs and content that instantly captivate visitors. These pages can showcase special holiday collections, promotions, and gift ideas, setting a festive mood and increasing the chances of higher conversion rates. By offering a visually appealing and relevant experience, you can increase the time visitors spend on your site and boost sales.
                    </p>
                    <p>
                        <b>Personalized Product Recommendations</b><br>
                        Implementing recommendation algorithms can significantly enhance the shopping experience. When customers see products that align with their preferences, they are more likely to make a purchase. By tailoring these recommendations to holiday-specific categories or gift ideas, you not only help customers find what they're looking for more easily but also drive up-sell and cross-sell opportunities, increasing your average order value.
                    </p>
                    <p>
                        <b>Countdown Timers</b><br>
                        Countdown timers create a sense of urgency and anticipation. You can use them for limited-time offers, shipping deadlines, or flash sales. When customers see a ticking clock, they're more likely to act quickly, which can lead to a surge in sales as they don't want to miss out on a great deal.
                    </p>
                    <p>
                        <b>Gift Guides & Certificates</b><br>
                        Curated gift guides help shoppers navigate your product offerings, making the selection process easier. By categorizing products into various gift guides, you simplify the decision-making process and encourage customers to make quicker purchasing decisions. This can result in increased sales and customer satisfaction. Providing custom gift certificates with special holiday offers or discounts can also create an engaging and memorable shopping experience.
                    </p>
                    <p>
                        <b>Limited Edition Products</b><br>
                        Creating limited edition products or collections exclusively for the festive season can generate excitement and a sense of exclusivity among your customers. Highlight these special products on your website with captivating visuals and descriptions. Limited edition items often sell out quickly, driving sales and creating a buzz around your brand.
                    </p>
                    <p>
                        <b>Personal Wishlists</b><br>
                        Allow customers to create wish lists or gift registries for themselves or to share with friends and family. These features simplify the gift-buying process and ensure that shoppers come back to your site to make their purchases, leading to increased sales and customer satisfaction.
                    </p>
                    <p>
                        <b>Last but not least: Holiday-Themed Content and Blogs</b><br>
                        Holiday-themed blog posts, articles, and content will offer valuable information, such as holiday planning tips, gift ideas, or DIY projects. This not only attracts organic traffic but also positions your brand as an expert in the holiday season. Increased site traffic can lead to more sales and higher brand authority.
                    </p>
                    <blockquote>
                        <i>
                            <b>Some holiday sales-inspired ideas turn into strategic business solutions</b>: our project for a major Fashion & Apparel developer in the UK/Ireland market was to fine-tune their discount tracking within the Global-e ERP system. Discounts applied broadly had to be evenly distributed at the product level. We devised a solution, dividing discounts fairly based on product price and quantity. Our custom API synced this data seamlessly with their ERP. <b>Result?</b> A streamlined discount tracking process for their marketing team, and enhanced overall efficiency for our client’s e-commerce business.
                        </i>
                    </blockquote>
                    <p>
                        Incorporating these festive-themed web solutions can significantly benefit your e-commerce business during the holiday season. They create a more enjoyable, personalized, and convenient shopping experience for customers, driving higher sales and establishing long-term customer relationships.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/driving-sales-home-for-the-holidays/driving-sales-home-for-the-holidays-img-2.jpg"
                            alt="Article image 2"
                            title="Article image 2"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        HOW A PROFESSIONAL WEB DEVELOPMENT AGENCY CAN HELP
                    </h2>
                    <p>
                        An expert web development agency can be your secret weapon for making the most of the festive rush. Here's a quick guide on how they can elevate your online store:
                    </p>
                    <p>
                        <i>Custom Development:</i> Picture a website as unique as your business. Web development companies like ours specialize in creating custom solutions tailored to your business needs. We can craft unique features and design elements to make your site stand out during the festive season.
                    </p>
                    <p>
                        <i>Performance Enhancement:</i> No one likes a slow website, especially during the high tide of the holidays. Web dev team can optimize your website's performance, ensuring it can handle increased traffic without slowdowns or crashes, providing a smooth shopping experience.
                    </p>
                    <p>
                        <i>Responsive Design:</i> A web development agency can make sure your site is accessible and functional on all devices. Easy navigation and quick purchases are the gifts that keep on giving.
                    </p>
                    <p>
                        <i>Search Engine Optimization (SEO):</i> When the holiday competition heats up, you want your products in the spotlight. A web dev company can improve your site's SEO, making it easier for potential customers searching for that perfect holiday find.
                    </p>
                    <p>
                        <i>Security Measures:</i> Protecting customer data is non-negotiable. E-commerce sites must prioritize security to protect customer data and transactions. Web development providers can implement robust security measures to safeguard your business and your customers.
                    </p>
                    <p>
                        The festive season is for online stores to shine, but it comes with its own set of challenges. The holiday hustle brings in demanding customers and fierce competition. Fear not, though, as gearing up your online store for this festive frenzy can make all the difference. With the right web development partner by your side, you'll be turning visitors into shoppers in no time.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="section_content__img">
                        <img
                            src="@/assets/blog/driving-sales-home-for-the-holidays/driving-sales-home-for-the-holidays-img-3.jpg"
                            alt="Article image 3"
                            title="Article image 3"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <p>
                        To make the most out of increased holiday traffic, contact our team and find out how <b>WDEVS</b> can support you this holiday season. It’s also important to note that while complex solutions take certain time to be developed, quick wins are possible – some of the ideas mentioned above can be crafted and put into action within a few days! Given our flexible pricing policy, it might just be your ultimate holiday bargain)
                    </p>
                    <p>
                        At <b>WDEVS</b>, our strategy is simple yet effective. We don't just create holiday-themed web features for short-term sales; we build solutions that contribute to your business's growth and sustainability year-round. We believe in cultivating enduring partnerships, and our digital solutions are crafted precisely for that purpose. This holiday season, let our <b>WDEVS</b> team be your key to success.
                    </p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon">
                                    <img
                                        v-svg-inline
                                        :data-src="require('@/assets/icons/rarr-icon.svg')"
                                        alt="" />
                                </span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";

export default {
    name: "BlogDrivingSalesHoliday",
    data() {
        return {
            backHistory: history.state.back,
            tags: ['Magento', 'eCommerce', 'Custom Web Development', 'Web Design', 'Magento Services'],
            scroll: false,
            position: null,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'Driving sales home for the holidays: simple web development solutions for boosting e-commerce during the holidays',
                },
            ],
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.article-blog-header {
    .article-blog-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 72px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .article-blog-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.article-blog-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.article-blog {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
            }
            &-link {
                display: inline-block;
                &:hover {
                    text-decoration: none;
                }
            }
            blockquote {
                margin: 25px 0;
                border-left: 3px solid lighten($base-text-color, 25%);
                padding: 10px 25px;
                color: lighten($base-text-color, 25%);
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.article-blog__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .article-blog__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.article-blog__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 72px;
        max-width: 1150px;
    }
}
.article-blog__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.article-blog__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
